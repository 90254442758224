import { operatorC } from "../common/operatorhelpers";


export const themeC = {
    Medina: 'medina',
    MedinaAr: 'medinaar',
    Blue: 'blue',
    Gold: 'gold',
    Jet: 'jet',
    Standard: 'standard',
    Sultan: 'sultan',
    Toto: 'toto',
    Blue2: 'blue2',
    Totome: 'totome',
    Betconstruct: 'betconstruct',
    Betconstructme: 'betconstructme',
    Hilton: 'hilton',
    Nora: 'nora',
    Metro: 'metro',
    Cwinz: 'cwinz',
    Win90: 'win90',
    Win90me: 'win90me',
    Manoto: 'manoto',
    Manotome: 'manotome',
    Betfa: 'betfa',
    Betfame: 'betfame',
    Takbet: 'takbet',
    Takbetme: 'takbetme',
    Light: 'light',
    Marcbet: 'marcbet',
    Marcbetme: 'marcbetme',
    Irbet365: 'irbet365',
    Irbet365me: 'irbet365me',
    Elit: 'elit',
    Kung: 'kung',
    Betbeto: 'betbeto',
    Dbet: 'dbet',
    Orange: 'orange',
    Wood: 'wood',
    Lion: 'lion',
    Video: 'video',
    MrVegas: 'mrvegas',
}

export const gradientsC =
{
    Betconstruct: {
        Max: "linear-gradient(135deg, #A68014 0%, #846b3e 100%)",
        Mix: "linear-gradient(135deg, #0F1634 0%, #2E2F54 100%)",
        Flex: "linear-gradient(135deg, #1F0141 0%, #9700A5 100%)",
        Landing: "linear-gradient(0deg, rgb(20,50,38) 0%, rgb(15,19,23) 100%)"
    },
    Blue2: {
        Max: "linear-gradient( -90deg, rgb(1,7,74) 0%, rgb(0,5,56) 100%)",
        Mix: "linear-gradient( 90deg, rgb(136,0,77) 0%, rgb(209,0,118) 100%)",
        Flex: "linear-gradient( -90deg, rgba(250,204,34,0.99608) 0%, rgb(238,179,0) 100%)",
        Landing: "linear-gradient( 0deg, rgb(0,4,31) 0%, rgb(0,9,48) 13%, rgb(0,14,64) 49%, rgb(0,9,47) 87%, rgb(0,3,29) 100%)"
    },
    Sultan: {
        Max: "linear-gradient( 335deg, #E8EC9B, #ADB520)",
        Mix: "linear-gradient( 335deg, #C8CABE, #727560)",
        Flex: "linear-gradient( 335deg, #BFC59F, #686E43)",
        Landing: "linear-gradient( 0deg, #202622 0%, #303A34 13%, #414D45 49%, #303A34 87%, #202622 100%)"
    },
    Kung: {
        Max: "linear-gradient(99.41deg, #004CFF 0.2%, #0099C9 99.8%)",
        Mix: "linear-gradient(99.41deg, #700000 0.2%, #6B0DB5 0.21%, #EB00FF 99.8%)",
        Flex: "linear-gradient(99.41deg, #07A85B 0.2%, #06E394 99.8%)"
    },
    Light: {
        Max: "linear-gradient(0deg, #B8CCF1 0%, #B8CCF1 100%)",
        Mix: "linear-gradient(0deg, #FAEEAE 0%, #FAEEAE 100%)",
        Flex: "linear-gradient(0deg, #E0D8EE 0%, #E0D8EE 100%)",
    },
    Dbet: {
        Max: "linear-gradient(103.87deg, #112F7C 10.41%, #13203C 89.59%)",
        Mix: "linear-gradient(103.87deg, #112F7C 10.41%, #13203C 89.59%)",
        Flex: "linear-gradient(103.87deg, #112F7C 10.41%, #13203C 89.59%)",
    },
    Orange: {
        Max: "linear-gradient(96.02deg, #FE9326 0%, #FE7800 99.7%)",
        Mix: "linear-gradient(96.02deg, #FE9326 0%, #FE7800 99.7%)",
        Flex: "linear-gradient(96.02deg, #FE9326 0%, #FE7800 99.7%)",
    },
    Wood: {
        Max: "linear-gradient(96.21deg, #BDA679 0%, #AA8E59 100%)",
        Mix: "linear-gradient(96.21deg, #BDA679 0%, #AA8E59 100%)",
        Flex: "linear-gradient(96.21deg, #BDA679 0%, #AA8E59 100%)",
    },
    Lion: {
        Max: "linear-gradient(96.02deg, #F18825 0%, #FD5C3C 99.7%)",
        Mix: "linear-gradient(96.02deg, #F18825 0%, #FD5C3C 99.7%)",
        Flex: "linear-gradient(96.02deg, #F18825 0%, #FD5C3C 99.7%)",
    }
}

export const productNamesC =
{
    Cwinz: {
        Max: "Large",
        Mix: "Medium",
        Flex: "Small"
    },
    Kung: {
        Max: "Super",
        Mix: "Super",
        Flex: "Super",
        Winter: "Vinter",
        Hockey: "Hockey"
    },
    Video: {
        Max: "Supertipset",
        Mix: "Supertipset",
        Flex: "Supertipset",
        Winter: "Vinter",
        Hockey: "Hockey"
    },
    Orange: {
        Max: "Fotboll",
        Mix: "Fotboll",
        Flex: "Fotboll",
        Hockey: "Hockey"
    },
    Betbeto: {
        Max: "Large",
        Mix: "Medium",
        Flex: "Small"
    },
}

export const GetMixMaxFlex = (roundName: string, theme: string) => {

    if (!IsVideo(theme)) {
        return roundName;
    }

    if (roundName.indexOf("Winter") > -1) {
        return productNamesC.Kung.Winter;
    }

    if (roundName.indexOf("Hockey") > -1) {
        return productNamesC.Kung.Hockey;
    }

    if (theme === themeC.Kung || theme === themeC.Dbet) {
        if (roundName.indexOf("Max") > -1) {
            return productNamesC.Kung.Max;
        }
        if (roundName.indexOf("Mix") > -1) {
            return productNamesC.Kung.Mix;
        }
        if (roundName.indexOf("Flex") > -1) {
            return productNamesC.Kung.Flex;
        }
    }

    if (roundName.indexOf("Max") > -1) {
        return productNamesC.Video.Max;
    }
    if (roundName.indexOf("Mix") > -1) {
        return productNamesC.Video.Mix;
    }
    if (roundName.indexOf("Flex") > -1) {
        return productNamesC.Video.Flex;
    }

    return roundName;
}

export const GetFlexNumber = (roundName: string) => {
    
    if (roundName.indexOf("Hockey") > -1) {
        return "7";
    }
    
    return "8";
}

export const IsMiddleEast = (theme: string) => {

    return (theme === themeC.Toto ||
        theme === themeC.Totome ||
        theme === themeC.Win90 ||
        theme === themeC.Win90me ||
        theme === themeC.Manoto ||
        theme === themeC.Manotome ||
        theme === themeC.Betfa ||
        theme === themeC.Betfame ||
        theme === themeC.Takbet ||
        theme === themeC.Takbetme ||
        theme === themeC.Marcbet ||
        theme === themeC.Marcbetme ||
        theme === themeC.Irbet365 ||
        theme === themeC.Irbet365me);

}

export const IsLeo = (theme: string) => {

    return (theme === themeC.Orange ||
        theme === themeC.Wood ||
        theme === themeC.Lion);

}

export const IsVideo = (theme: string) => {

    return (theme === themeC.Kung ||
        theme === themeC.Dbet ||
        theme === themeC.Video ||
        theme === themeC.MrVegas
    );

}

export const ShowImageOnLandingPage = (theme: string) => {
    if (theme === themeC.Gold || theme === themeC.Medina || window.theme === themeC.MedinaAr) return false;
    return true
}

export const UseBetConstructDefault = (theme: string) => {
    if (theme === themeC.Nora || theme === themeC.Hilton) return true;
    return false
}

//Special formatted round and robot plates on landing page
export const UseSlimRobotPlates = (theme: string) => {
    if (theme === themeC.Gold || theme === themeC.Medina || theme === themeC.MedinaAr ||
        theme === themeC.Blue2 || IsLeo(theme) || theme === themeC.Betconstruct || theme === themeC.Betconstructme || theme === themeC.Sultan
        || theme === themeC.Elit || theme === themeC.Metro || theme === themeC.Cwinz || IsVideo(theme) || theme === themeC.Betbeto)
        return true;
    return false
}

//Special formatted coupon header
export const UseSlimCouponHeader = (theme: string) => {
    if (theme === themeC.Gold || theme === themeC.Medina || theme === themeC.MedinaAr || theme === themeC.Blue2
        || theme === themeC.Sultan || IsLeo(theme) ||
        theme === themeC.Betconstruct || theme === themeC.Betconstructme || theme === themeC.Elit || theme === themeC.Metro
        || IsVideo(theme) || theme === themeC.Cwinz || theme === themeC.Betbeto)
        return true;
    return false
}

export const UsePoolXLogoInRoundList = (theme: string) => {
    if (theme === themeC.Gold || theme === themeC.Medina || theme === themeC.MedinaAr || theme === themeC.Blue2
        || IsLeo(theme) || theme === themeC.Sultan ||
        theme === themeC.Betconstruct || theme === themeC.Betconstructme || theme === themeC.Elit
        || theme === themeC.Metro || theme === themeC.Cwinz || IsVideo(theme) || theme === themeC.Betbeto)
        return false;
    return true
}

//Right to left theme (Ex. Arabic and Farsi)
export const IsRightToLeftTheme = (theme: string) => {
    if (theme === themeC.Totome || theme === themeC.Betconstructme || theme === themeC.Win90me
        || theme === themeC.Manotome || theme === themeC.Betfame || theme === themeC.Takbetme
        || theme === themeC.Marcbetme || theme === themeC.Irbet365me || theme === themeC.MedinaAr)
        return true;
    return false
}

export function getTheme(urlSearchParams: URLSearchParams, languageSetTo: string, operatorId: string) {

    let pTheme = urlSearchParams.get('theme');

    if (pTheme === undefined || pTheme === null || pTheme === "") {
        pTheme = themeC.Light;
    }

    if (operatorId === operatorC.IrToto || operatorId === operatorC.IrTotoTest) {
        pTheme = themeC.Toto;
    }

    if (pTheme === themeC.Toto && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Totome;
    }

    if (operatorId === operatorC.Marcbet) {
        pTheme = themeC.Marcbet;
    }

    if (pTheme === themeC.Marcbet && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Marcbetme;
    }

    if (operatorId === operatorC.IrBet365) {
        pTheme = themeC.Irbet365;
    }

    if (pTheme === themeC.Irbet365 && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Irbet365me;
    }

    if (operatorId === operatorC.Win90) {
        pTheme = themeC.Win90;
    }

    if (pTheme === themeC.Win90 && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Win90me;
    }

    if (operatorId === operatorC.Manotobet) {
        pTheme = themeC.Manoto;
    }

    if (pTheme === themeC.Manoto && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Manotome;
    }

    if (operatorId === operatorC.Betfa) {
        pTheme = themeC.Betfa;
    }

    if (pTheme === themeC.Betfa && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Betfame;
    }

    if (operatorId === operatorC.Takbet) {
        pTheme = themeC.Takbet;
    }

    if (pTheme === themeC.Takbet && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Takbetme;
    }

    if (UseBetConstructDefault(pTheme)) {
        pTheme = themeC.Betconstruct;
    }

    if (pTheme === themeC.Betconstruct && (languageSetTo === "fa" || languageSetTo === "ar")) {
        pTheme = themeC.Betconstructme;
    }

    if (pTheme === themeC.Medina && languageSetTo === "ar") {
        pTheme = themeC.MedinaAr;
    }

    if (pTheme === themeC.Blue) {
        pTheme = themeC.Blue2;
    }

    return pTheme;
}

