export const TRANSLATIONS_DE = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    
    my_bets: "Meine Wetten",
    my_coupons: "Meine Gutscheine",
    rounds: "Runden",    
    bet_with_syndicates: "Mit Syndikaten wetten",
    
    syndicates: "Syndikate",
    turnover: "Aktueller Pool",
    
    single_rows: "Einzelne Reihen",
    pro_pick: "Profi-Auswahl",
    often: "Häufig gewinnen",
    a_lot: "Viel gewinnen",
    system: "System",
    row_distribution: "Reihenverteilung",
    overview: "Übersicht",
    full_cover: "Vollabdeckung",
    half_cover: "Halbabdeckung",
    row_price: "Reihenpreis",
    pay: "Wette platzieren",
    
    unsettled_coupons: "Offene Gutscheine",
    settled_coupons: "Abgeschlossene Gutscheine",
    stake: "Einsatz",
    coupon_share_status_cancelled: "Abgebrochen",
    your_winnings_right_now: "Deine Gewinne aktuell",
    correct_rows_now: "Richtig gewählte Picks jetzt",
    starts: "Startet",
    correct_rows: "Richtige Reihen",
    prize: "Preis",
    your_rows: "Deine Reihen",
    profit: "Gewinn",
    st: "",
    total: "Ergebnis",
    rows: "Reihen",
    row: "Reihe",
    price_per_row: "Preis pro Reihe",
    total_cost: "Gesamtkosten",
    paid: "Bezahlt",
    settled: "Abgeschlossen",
    channel_internet: "Kanal: Internet",
    print: "Drucken",
    share: "Teilen",    
    clear_bets: 'Wetten löschen',

    
    reduce: "Reduzieren",
    no_reduced_systems_available: "Keine reduzierten Systeme verfügbar",
    round_history: "Rundenverlauf",
    
    no_profit: "Kein Gewinn",
    no_coupons: "Keine Gutscheine",
    started: "Gestartet am",
    round: "Runde",
    winners: "Gewinner",
    your_bet_is_paid: "Deine Wette wurde platziert!",
    do_you_want_to_place_bet: "Möchtest du eine Wette platzieren von",
    
    highest_win: "Höchster Gewinn",
    insufficient_funds: "Unzureichendes Guthaben",
    cancel: "Abbrechen",
    unable_to_complete_purchase: 'Kauf konnte nicht abgeschlossen werden',
    error_code: 'Fehlercode',

    event_goal: 'Tor',
    event_penalty: 'Elfmeter',
    event_missed_penalty: 'Verfehlter Elfmeter',
    event_own_goal: 'Eigentor',
    event_var: 'VAR',
    event_yellowcard: 'Gelbe Karte',
    event_yellowred: 'Zweite Gelbe Karte',
    event_substitution: 'Auswechslung',
    event_redcard: 'Rote Karte',
    event_pen_shootout_goal: 'Tor aus einem Elfmeter',
    event_pen_shootout_miss: 'Verfehlter Elfmeter',
    event_full_time: 'Vollzeit',
    event_half_time: 'Halbzeit',
    event_kickoff: 'Anpfiff',
    event_finished_after_extra: 'Verlängerung beendet',

    out: 'Aus',

    
    table: "Tabelle",
    game_info: "Spielinformationen",
    statistics: "Statistiken",
        
    team_name: "Team",
    table_abbreviation_num_games: "T",
    table_abbreviation_wins: "S",
    table_abbreviation_draws: "U",
    table_abbreviation_losses: "N",
    table_abbreviation_points: "P",

    round_active_after_deadline: 'Geschlossen',
    full_time: "Vollzeit",
    round_status_finished : "Beendet",
    half_time: 'Halbzeit',
    
    current_rounds: "Aktuelle Runden",
    settled_rounds: "Abgeschlossene Runden",
    no_rounds_available: "Keine Runden verfügbar",

    row_statistics: 'Reihenstatistik',
    
    showing: 'Zeigt',    
    settings: 'Einstellungen',
    match: 'Spiel',
    result: 'Ergebnis',    
    bet_type: "Wettart",    
    mathematical_system: "Mathematisches System",
    reduced_system: "Reduziertes System",
    no_system: "Kein System",
    select: "Auswählen",
    is_draw: "Wird gezogen mit",
    is_drawn: "Gezogen",
    draw_disclaimer: 'Wenn von der Auslosung betroffen:',
    num_correct_win: 'Anzahl richtig/gewonnen',
    more_info: 'Mehr Info',
    in_running: 'In der laufenden Partie',
  
    
    go_back: 'Zurück gehen',

    to_syndicate: "Zu Syndikat",
    shares: "Anteile",
    share_price: "Anteilspreis",
    sold_shares: "Sold shares",
    agent_admin: "Verkaufte Anteile",
    agents: "Agenten",
    to_agent: "An Agent",
    create_new_syndicate: "Neues Syndikat erstellen",
    name: "Name",
    target_number_of_shares: "Zielanzahl der Anteile",
    max_number_of_shares: "Maximale Anzahl der Anteile",
    submit_syndicate: "Syndikat einreichen",
    update_syndicate: "Syndikat aktualisieren",
    show_sv_percent: 'Spieler anzeigen %',
    show_coupon_percent: 'Gutschein anzeigen %',
    number_of_rows: 'Anzahl der Reihen',
    share_of_syndicate: 'Anteil am Syndikat',
    syndicate_created: 'Syndikat erstellt',
    syndicate_submitted: 'Syndikat eingereicht',
    syndicate_updated: 'Syndikat aktualisiert',
    finished: 'Beendet',
    started_games: 'Gestartet',
    potential: 'Potenzial',
    sort_by_finished: 'Sortieren nach Beendigung',
    game_summary: 'Spielzusammenfassung',
    date: 'Datum',
    league: 'Liga',
    venue: 'Spielort',
    latest_tweets: 'Neueste Tweets von',
    weather: 'Wetter',
    temperature: 'Temperatur',
    wind: 'Wind',
    moisture: 'Luftfeuchtigkeit',
    general_info: 'Allgemeine Informationen',
    locale: 'de-DE',
    show: 'Anzeigen',
    show_round: 'Runde anzeigen',
    play_on: 'Weiter spielen',
    today: 'Heute',
    latest_games: 'Neueste Spiele',
    head_to_head: 'Direkter Vergleich',
    round_information_short: 'Informationen',
    round_information: 'Rundeninformationen',
    prize_groups: 'Gewinngruppen',
    bet: 'Wette',
    pro_pick_bets: 'Profi-Auswahlwetten',
    help: 'Hilfe',
    round_status_open : "Öffnen",
    round_status_voided : "Abgebrochen",
    round_status_paused : "Pausiert",
    round_paused_explanation: 'Die Runde ist vorübergehend für Wetten pausiert',
    jackpot : "Geschätzter Jackpot",
    after_extra_time: "Nach Verlängerung",
    system_guarantee: "Richtigkeitsgarantie",
    reduced_disclaimer:"In einem reduzierten System wurde die Anzahl der Reihen des entsprechenden mathematischen Systems.",
    show_rows:"Meine Reihen anzeigen",
    api_betting:"Datei hochladen",
    select_file:"Datei auswählen",
    file:"Datei",
    id:"Id",
    upload:"Hochladen",
    bet_title:"Wette",
    follow_title:"Folgen",
    win_title:"Gewinnen",
    bet_text:"Wähle in jedem Fußballspiel der Runde ein oder mehrere Ergebnisse aus",
    follow_text:"Verfolge deine Gewinne live auf der Website",
    win_text:"Wenn du genügend richtige Ergebnisse ausgewählt hast, wirst du einer der Gewinner des Pools sein",
    share_of_prizepool: "Anteil am Gewinnpool",
    register: "Registrieren",
    fee_per_row: "Gebühr pro Reihe",
    bet_status_open: "Öffnen",
    bet_status_won: "Gewonnen",
    bet_status_lost: "Verloren",
    payout: "Auszahlung",
    show_details: "Details anzeigen",
    bet_now: "Jetzt wetten",
    place_more: "Wenn du deine Gewinnchancen erhöhen möchtest, kannst du in jedem Spiel mehrere Ergebnisse auswählen",
    active_syndicates: "Aktive Syndikate",
    settled_syndicates: "settled syndicates",
    win_multiplier: "Gewinnmultiplikator",
    win_multiplier_information: "Ihr Einsatz und Ihr potenzieller Gewinn werden mit dem ausgewählten Gewinnmultiplikator multipliziert",
    status: "Status",
    balance: "Balance",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
    odds:"Odds",
}
