export const TRANSLATIONS_SV = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",

    my_bets: "Mina Spel",
    my_coupons: "Mina kuponger",
    rounds: "Omgångar",
    
    bet_with_syndicates: "Andelsspel",
    
    syndicates: "Andelsspel",
    turnover: "Omsättning",
    
    single_rows: "Enkelrader",
    pro_pick: "Poolaren",
    often: "Vinn ofta",
    a_lot: "Vinn mycket",
    system: "System",
    row_distribution: "Radfördelning",
    overview: "Summering",
    full_cover: "Helgarderingar",
    half_cover: "Halvgarderingar",
    row_price: "Radpris",
    pay: "Lägg spel",
    
    unsettled_coupons: "Pågående kuponger",
    settled_coupons: "Avgjorda kuponger",
    stake: "Insats",
    coupon_share_status_cancelled: "Makulerad",
    your_winnings_right_now: "Din vinst just nu",
    correct_rows_now: "Rätt just nu",
    starts: "Startar",
    correct_rows: "Antal rätt",
    prize: "Utdelning",
    your_rows: "Dina rader",
    profit: "Vinst",
    st: "st",
    total: "Resultat",
    rows: "rader",
    row: "rad",
    price_per_row: "Insats per rad",
    total_cost: "Total insats",
    paid: "Betalt",
    settled: "Avgjordes",
    channel_internet: "Spelat via: Internet",
    print: "Skriv ut",
    share: "Dela",
        
    reduce: "Reducera",
    no_reduced_systems_available: "No reduced systems available",
    round_history: "Resultat",
    
    no_profit: "Ingen vinst",
    no_coupons: "Inga Kuponger",
    started: "Startade",
    round: "Spelomgång",
    winners: "Vinnare",
    your_bet_is_paid: "Ditt spel är lagt!",
    do_you_want_to_place_bet: "Vill du lägga ett spel för",
    
    highest_win: "Högsta vinst",
    insufficient_funds: "För lågt saldo",
    gaming_limits_reached: "Ditt spel kunde inte placeras då din spelgräns är uppnådd",
    cancel: "Avbryt",
    unable_to_complete_purchase: 'Köpet kunde inte genomföras',
    error_code: 'Felkod',

    event_goal: 'Mål',
    event_penalty: 'Straff',
    event_missed_penalty: 'Missad Straff',
    event_own_goal: 'Självmål',
    event_var: 'VAR',
    event_yellowcard: 'Gult Kort',
    event_yellowred: 'Andra Gula Kortet',
    event_substitution: 'Byte',
    event_redcard: 'Rött Kort',
    event_pen_shootout_goal: 'Mål vid Straff',
    event_pen_shootout_miss: 'Missad Straff',
    event_full_time: 'Fulltid',
    event_half_time: 'Halvtid',
    event_kickoff: 'Avspark',
    event_finished_after_extra: 'Tilläggstid Slut',

    out: 'Ut',

    
    table: "Tabell",
    game_info: "Matchinfo",
    statistics: "Statistik",
    
    in_running: "Live",
    full_time: "Fulltid",
    round_status_finished : "Avslutad",
    half_time: 'Halvtid',
    
    team_name: "Lag",
    table_abbreviation_num_games: "M",
    table_abbreviation_wins: "V",
    table_abbreviation_draws: "O",
    table_abbreviation_losses: "F",
    table_abbreviation_points: "P",

    current_rounds: "Aktuella Omgångar",
    settled_rounds: "Avslutade Omgångar",
    no_rounds_available: "Inga tillgängliga omgångar",

    row_statistics: 'Radstatistik',
    
    showing: 'Visar',    
    settings: 'Inställningar',
    match: 'Match',
    result: 'Resultat',
    bet_type: "Systemtyp",    
    mathematical_system: "Matematiskt system",
    reduced_system: "Reducerat system",
    no_system: "Inget system",
    select: "Välj",
    is_draw: "Lottas med",
    is_drawn: "Lottad",
    draw_disclaimer: 'Om lottning tillämpas:',
    num_correct_win: 'Antal rätt/vinst',
    more_info: 'Mer info',
    clear_bets: 'Rensa',
    
    go_back: 'Tillbaka',

    to_syndicate: "Till andelsspelet",
    shares: "Andelar",
    share_price: "Andelspris",    
    sold_shares: "Sålda andelar",
    agent_admin: "Ombud admin",
    agents: "Ombud",
    to_agent: "Till ombudet",
    create_new_syndicate: "Skapa nytt andelsspel",
    name: "Namn",
    target_number_of_shares: "Antal andelar",
    max_number_of_shares: "Max antal andelar",
    submit_syndicate: "Lämna in andelsspelet",
    update_syndicate: "Uppdatera andelsspelet",
    show_sv_percent: 'Visa pool %',
    show_coupon_percent: 'Visa kupong %',
    number_of_rows: 'Antal rader',
    share_of_syndicate: 'Andel av andelsspelet',
    syndicate_created: 'Andelsspelet skapat',
    syndicate_submitted: 'Andelsspelet inlämnat',
    syndicate_updated: 'Andelsspelet uppdaterat',
    finished: 'Avslutade',
    started_games: 'Påbörjade',
    potential: 'Potential',
    sort_by_finished: 'Sortera efter avslutade',
    game_summary: 'Matchsummering',
    date: 'Datum',
    league: 'Liga',
    venue: 'Arena',
    latest_tweets: 'Senaste tweets från',
    weather: 'Väder',
    temperature: 'Temperatur',
    wind: 'Vind',
    moisture: 'Fuktighet',
    general_info: 'Allmän info',
    locale: 'sv-SV',
    show: 'Visa',
    show_round: 'Visa omgång',
    play_on: 'Spela på',
    today: 'Idag',
    latest_games: 'Senaste matcherna',
    head_to_head: 'Head to head',
    round_information_short: 'Info',
    round_information: 'Information',
    prize_groups: 'Vinstgrupper',
    bet: 'Spela',
    pro_pick_bets: 'Färdiga spel',
    help: 'Hjälp',
    round_status_open : "Öppen",
    round_status_voided : "Inställd",
    round_status_paused : "Pausad",
    round_active_after_deadline: 'Stängd',
    round_paused_explanation: 'Omgången är pausad och nya spel kan inte läggas',
    jackpot : "Uppskattad Jackpot",
    after_extra_time: "Efter Tilläggstid",
    system_guarantee: "rättsgaranti",
    reduced_disclaimer:"I ett reducerat system har man minskat antalet rader från motsvarande matematiska system. Man får därmed lägre chans att vinna men systemet kostar också mindre.",
    show_rows:"Visa mina rader",
    api_betting:"Filuppladdning",
    select_file:"Välj fil",
    file:"Fil",
    id:"Id",
    upload:"Ladda upp",
    bet_title:"Tippa",
    follow_title:"Följ",
    win_title:"Vinn",
    bet_text:"Välj ett eller flera utfall i omgångens matcher",
    follow_text:"Följ dina vinstchanser live på siten",
    win_text:"Har du tillräckligt många rätt är du med och delar på potten. 80% av insatserna delas ut som vinster.",
    share_of_prizepool: "Andel av prispotten",
    register: "Registrera",
    fee_per_row: "Avgift per rad",
    bet_status_open: "Öppet",
    bet_status_won: "Vunnet",
    bet_status_lost: "Förlorat",
    payout: "Utbetalning",
    show_details: "Visa detaljer",
    bet_now: "Spela nu",
    place_more: "If you want to increase your chances of winning you can pick more than one outcome in each game",
    active_syndicates: "aktiva andelsspel",
    settled_syndicates: "avgjorda andelsspel",
    win_multiplier: "Vinstmultiplikator",
    win_multiplier_information: "Din insats och potentiella vinst multipliceras med den valda vinstmultiplikatorn.",
    balance: "Balans",
    status: "Status",

    join_title:"Gå med i ett andelsspel",
    join_text:"Ta del av ett större system och öka dina chanser att vinna potten.",
    valid_title:"Alltid giltigt",
    valid_text:"Ditt spel gäller även om inte andelsspelet säljs slut",
    win_together_title:"Vinn tillsammans",
    win_together_text:"Vinsterna från systemet delas mellan andelarna.",
    fee:"Avgift",
    error_title:"Någonting gick fel",
    error_text:"Försök att ladda om sidan, eller gå tillbaka till hemsidan.",
    reload:"Ladda om",
    odds:"Odds",
}