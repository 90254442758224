export const TRANSLATIONS_AM = {
    locale: 'am-ET',
    max: "ከፍተኛው",
    mix: "ቅልቅል",
    flex: "ፍሌክስ",
    my_bets: "የኔ ውርርድ",
    my_coupons: "ትኬቶች",
    rounds: "ዙር",
    bet_with_syndicates: "ሲንዲኬት",
    syndicates: "ሲንዲኬት",
    turnover: "ያሁኑ ጥርቅም",
    single_rows: "ነጠላ ረድፍ",
    pro_pick: "ፕሮ ምርጫ",
    often: "ብዙ ጊዜ ያሸንፉ",
    a_lot: "ብዙ ያሸንፉ",
    system: "ሲስተም",
    row_distribution: "ረድፍ ስርጭት",
    overview: "ምልከታ",
    full_cover: "ሙሉ ሽፋን",
    half_cover: "ግማሽ ሽፋን ",
    row_price: "የረድፍ ዋጋ",
    pay: "ይመድቡ",
    unsettled_coupons: "ይልተስተካከሉ ኩፖኖች",
    settled_coupons: "የተስተካከሉ ኩፖኖች",
    stake: "ዋጋ",
    coupon_share_status_cancelled: "የተሰረዘ",
    your_winnings_right_now: "እስካሁን ያሸነፉት",
    correct_rows_now: "ያሁን ትክክለኛ ረድፎች",
    starts: "ይጀምራል",
    correct_rows: "ትክክለኛ ረድፎች",
    prize: "ሽልማት",
    your_rows: "የርሶ ረድፍ",
    profit: "ትርፍ",
    total: "ውጤት",
    rows: "ረድፎች",
    row: "ረድፍ ",
    price_per_row: "የ አንድ ረድፍ ዋጋ ",
    total_cost: "አጠቃላይ ዋጋ",
    paid: "ተክፍሎአል",
    settled: "ተፈቶአል",
    channel_internet: "ቻናል ኢንተርኔት",
    print: "ማተም",
    share: "ሼር",
    clear_bets: "ውርርዶን ያጥፉ",
    round_history: "የዙር ታሪክ",
    no_profit: "ትርፍ የለም",
    no_coupons: "ኩፖን የለም",
    started: "ሚጀምርበት",
    round: "ዙር",
    winners: "አሸናፊዎች",
    your_bet_is_paid: "ውርርዶ ተሳክቶአል",
    do_you_want_to_place_bet: "ውርርድ ማረግ ይፈልጋሉ",
    highest_win: "ትልቁ አሸናፊ",
    insufficient_funds: "በቂ ገንዘብ የሎትም",
    cancel: "ሰርዝ",
    unable_to_complete_purchase: "ግብይቱ አልተሳካም",
    error_code: "የተሳሳተ ኮድ",
    event_goal: "ጎል",
    event_penalty: "ፍጹም ቅጣት ምት",
    event_missed_penalty: "የተሳተ ፍጹም ቅጣት ምት",
    event_own_goal: "ራስ ላይ ጎል",
    event_var: "ቫር",
    event_yellowcard: "ቢጫ ካርድ",
    event_yellowred: "ሁለተኛ ቢጫ ካርድ ",
    event_substitution: "ቅያሪ",
    event_redcard: "ቀይ ካርድ ",
    event_pen_shootout_goal: "የፍጹም ቅጣት ምት ጎል",
    event_pen_shootout_miss: "የተሳተ ፍጹም ቅጣት ምት",
    event_full_time: "ሙሉ ሰአት",
    event_half_time: "ግማሽ ሰአት",
    event_kickoff: "ጅማሬ",
    event_finished_after_extra: "ተጨማሪ ሰአቱ አልቆአል",
    out: "ወጥቶአል",
    table: "ጠረጴዛ",
    game_info: "የጨዋታ ኢንፎርሜሽን",
    statistics: "ስታቲክስ",
    team_name: "ቡድን ",
    table_abbreviation_num_games: "ጂ",
    table_abbreviation_wins: "ዊ",
    table_abbreviation_draws: "ድ",
    table_abbreviation_losses: "ኤል",
    table_abbreviation_points: "ፒ",
    round_active_after_deadline: "ተዘግቶአል",
    full_time: "ሙሉ ሰአት",
    round_status_finished: "ተጠናቀቀ",
    half_time: "ግማሽ ሰአት",
    current_rounds: "ያሁኑ ዙር",
    settled_rounds: "የተሳኩ ዙሮች",
    no_rounds_available: "ዙሮች የሉም",
    row_statistics: "የረድፍ ስታስቲክስ",
    showing: "አሳይ",
    settings: "ማስተካከያ",
    match: "ግጥሚያ",
    result: "ውጤት",
    bet_type: "የ ውርርድ አይነት",
    mathematical_system: "ሂሳባዊ ሲስተም",
    select: "ምረጥ",
    is_draw: "ጋር ይወጣል",
    is_drawn: "ወጥቶአል",
    draw_disclaimer: "ለማውጣት ከተፈለገ",
    num_correct_win: "ትክክል ቁጥር/ያሸንፋል",
    more_info: "ተጨማሪ መረጃ",
    in_running: "በሩጫ ላይ",
    go_back: "ተመለስ",
    to_syndicate: "ወደ ሲኒዲኬትስ",
    shares: "ሼር",
    share_price: "የሼር ዋጋ",
    sold_shares: "የተሸጡ ሼሮች",
    agent_admin: "ወኪል አስተዳዳሪ",
    agents: "ወኪሎች",
    to_agent: "ወደ ወኪል",
    create_new_syndicate: "አዲስ ሲኒዲኬትስ ይፍጠሩ",
    name: "ስም",
    target_number_of_shares: "የሼር ዒላማ ቁጥር",
    max_number_of_shares: "ከፍተኛው የሼር ብዛት",
    submit_syndicate: "ሲኒዲኬትስ አስገባ",
    update_syndicate: "ሲኒዲኬትስ ያዘምኑ",
    show_sv_percent: "ተጫዋቾችን አሳይ %",
    show_coupon_percent: "ኩፖን አሳይ %",
    number_of_rows: "የረድፎች ብዛት",
    share_of_syndicate: "ሲኒዲኬትስ ድርሻ",
    syndicate_created: "ሲኒዲኬትስ ተፈጠረ",
    syndicate_submitted: "ሲኒዲኬትስ ገብቷል",
    syndicate_updated: "ሲኒዲኬትስ ተስተካክሎአል",
    finished: "ጨርሷል",
    started_games: "ተጀመረ",
    potential: "አቅም",
    sort_by_finished: "በተጠናቀቀ ደርድር",
    game_summary: "የጨዋታ ማጠቃለያ",
    date: "ቀን",
    league: "ሊግ",
    venue: "ቦታ",
    weather: "የአየር ሁኔታ",
    temperature: "የሙቀት መጠን",
    wind: "ንፋስ",
    moisture: "እርጥበት",
    general_info: "አጠቃላይ መረጃ",
    show: "አሳይ",
    show_round: "ዙር አሳይ",
    play_on: "ይጫወቱ",
    today: "ዛሬ",
    latest_games: "የቅርብ ጊዜ ጨዋታዎች",
    head_to_head: "የ እርስ በእርስ ግኑኝነቶች",
    round_information_short: "መረጃ",
    round_information: "ዙርመረጃ",
    prize_groups: "የሽልማት ቡድኖች",
    bet: "ውርርድ",
    pro_pick_bets: "ፕሮ ውርርድ ይምረጡ",
    help: "እገዛ",
    round_status_open: "ክፈት",
    round_status_voided: "ተሰርዟል",
    round_status_paused: "ባለበት ቆሟል",
    round_paused_explanation: "ዙሩ ለውርርድ ለጊዜው ቆሟል",
    jackpot: "የተገመተው Jackpot",
    after_extra_time: "ከተጨማሪ ጊዜ በኋላ",
    show_rows: "ረድፎቼን አሳይ",
    id: "መታወቂያ",
    bet_title: "ውርርድ",
    follow_title: "ተከተል",
    win_title: "ያሸንፉ",
    bet_text: "በእያንዳንዱ የዙሩ የእግር ኳስ ጨዋታዎች ላይ አንድ ወይም ከዚያ በላይ ውጤቶችን ይምረጡ",
    follow_text: "ድሎችዎን በዌብሳይቱ ላይ በቀጥታ ይከታተሉ",
    win_text: "በቂ ትክክለኛ ውጤቶችን ከመረጡ ከፑሉ አሸናፊዎች አንዱ ይሆናሉ",
    share_of_prizepool: "የሽልማት ፑል ድርሻ",
    register: "ይመዝገቡ",
    fee_per_row: "ክፍያ በአንድ ረድፍ",
    bet_status_open: "ክፈት",
    bet_status_won: "አሸነፈ",
    bet_status_lost: "ተሸነፈ",
    payout: "ክፍያ",
    show_details: "ዝርዝሮችን አሳይ",
    bet_now: "አሁን ተወራረድ",
    place_more: "የማሸነፍ እድሎዎን ለመጨመር ከፈለጉ በእያንዳንዱ ጨዋታ ከአንድ በላይ ውጤት መምረጥ ይችላሉ።",
    active_syndicates: "ንቁ ሲንዲድስ",
    settled_syndicates: "የተጠናቀቁ ሲኒዲኬትስ",
    win_multiplier: "ብዙ ያሸንፉ",
    win_multiplier_information: "የእርስዎ ድርሻ እና አቅም በተመረጠው የአሸናፊነት ማባዣ ተባዝቷል።",
    status: "ሁኔታዎች",
    join_title: "ቡድኑን ይቀላቀሉ",
    join_text: "ከሌሎች ተጫዋቾች ጋር በአንድ ትልቅ ስርዓት ውስጥ ይሳተፉ እና የጃክፖቱን አሸናፊነት እድል ይጨምሩ።",
    valid_title: "ሁልጊዜ የሚሰራ",
    valid_text: "ሲኒዲኬቱ ተሽጦ ባያልቅም ውርርድዎ ግን ሁል ጊዜ የሚሰራ ነው።",
    win_together_title: "በጋራ አሸንፉ",
    win_together_text: "ከሲኒዲኬቱ የተገኙት ድሎች በባለድርሻዎች መካከል የሚከፋፈሉ ናቸው።",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
    odds:"Odds",
}
